import axios, { AxiosResponse } from 'axios';
import { WishListRequestModel, WishList, WishListProduct, WishListItem } from '@/models/WishList';
import { PriceListExportItems } from '@/models/PriceList';

export default class WishListService {

    private profileId: string = (window as any).CbxApiContextKey;
    private endpoint: string;

    constructor(args: any = {}) {
        if (args.profileId)
            this.profileId = args.profileId;
        this.endpoint = `/contextapi/${this.profileId}/v1/wishlist`;
    }

    public async getWishLists(): Promise<WishListRequestModel> {
        try {
            const res: AxiosResponse<any> = await axios.get(`${this.endpoint}`);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async removeWishList(listid: string): Promise<string> {
        try {
            await axios.delete(`${this.endpoint}/${listid}`);
            return listid;
        } catch (e) {
            console.log(e);
        }
    }

    public async addWishListItem(item: WishListItem): Promise<boolean> {
        try {
            const res: AxiosResponse<any> = await axios.post(`${this.endpoint}`, item);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    public async removeWishListItem(listid: string, productid: string): Promise<boolean> {
        try {
            const res: AxiosResponse<any> = await axios.delete(`${this.endpoint}/${listid}/${productid}`);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    public async updateWishList(payload: WishList): Promise<WishList> {
        try {
            const res: AxiosResponse<WishList> = await axios.post(`${this.endpoint}/updatelist`, payload);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }

    public async toWishListFromPriceList(payload: PriceListExportItems): Promise<WishList> {
        try {
            const res: AxiosResponse<WishList> = await axios.post(`${this.endpoint}/frompricelist`, payload);
            return res.data;
        } catch (e) {
            console.log(e);
        }
    }
}